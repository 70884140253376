import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/:channel(channel[1-9]\\d?)?',
		component: () => import('../components/Layout.vue'),
		children: [
			{
				path: '',
				name: 'home',
				component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
			},
			{
				path: 'meaning',
				name: 'meaning',
				component: () => import('../pages/meaning.vue'),
			},
			{
				path: 'blogs',
				name: 'blogs',
				component: () => import('../pages/blogs.vue'),
			},
			{
				path: 'blogs/the-history-and-origins-of-tarot-cards',
				name: 'blog1',
				component: () => import('../pages/blog1.vue'),
			},
			{
				path: 'blogs/interpreting-tarot',
				name: 'blog2',
				component: () => import('../pages/blog2.vue'),
			},
			{
				path: 'blogs/tarot-reading-techniques',
				name: 'blog3',
				component: () => import('../pages/blog3.vue'),
			},
			{
				path: 'blogs/the-integration-of-tarot-and-astrology',
				name: 'blog4',
				component: () => import('../pages/blog4.vue'),
			},
		],
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	// 最后放置通配路由
	{
		path: '*',
		redirect: { name: 'home' },
	},
]

Vue.mixin({
	methods: {
		customPush(path) {
			const { params, query } = this.$route
			const channel = params.channel || ''
			const queryString = new URLSearchParams(query).toString()
			const fullChannel = channel ? `/${channel}` : ''
			const fullQueryString = queryString ? `?${queryString}` : ''

			this.$router.push(`${fullChannel}${path}${fullQueryString}`)
		},
	},
})

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
